import '../css/Footer.css'; // Importa o arquivo css do footer

/**
 * @name Footer
 * @description Retorna o componente do rodapé / footer
 * @param {Object} props 
 * @param {Object} props.theme
 */

function Footer({ theme }) {
  return (
    <footer className='footer' style={{ background: theme.footer, color: theme.textColor }}>
      <div className='footer-left'>
        <a style={{ color: theme.textColor }} href='/'>INÍCIO</a>
        <a style={{ color: theme.textColor }} href='/contato'>CONTATO</a>
        <a style={{ color: theme.textColor }} href='/projetos'>PROJETOS</a>
      </div>

      <div className='full-name'>
        <h2>NEXTDIV</h2>
        <h3>DESENVOLVIMENTO DE SOFTWARE</h3>
      </div>

      <h2 className='short-name'>NEXTDIV</h2>

      <div className='footer-right'>
        <a target='_blank'
          href='https://www.instagram.com/next.div/' 
          rel='noreferrer'
          style={{ color: theme.textColor, background: theme.socialMedias }}
        >
          <i className='bx bxl-instagram-alt'></i>
        </a>
        <a target='_blank'
          rel='noreferrer'
          href='https://github.com/NextDiv' 
          style={{ color: theme.textColor, background: theme.socialMedias }}
        >
          <i className='bx bxl-github'></i>
        </a>
        <a target='_blank'
          href='https://www.youtube.com/@next-div'
          rel='noreferrer'
          style={{ color: theme.textColor, background: theme.socialMedias }}
        >
          <i className='bx bxl-youtube'></i>
        </a>
        <a target='_blank'
          href='https://www.linkedin.com/in/next-div-213b972bb/' 
          rel='noreferrer'
          style={{ color: theme.textColor, background: theme.socialMedias }}
        >
          <i class='bx bxl-linkedin-square'></i>
        </a>
      </div>
    </footer>
  );
}

// Exporta o componente
export default Footer
