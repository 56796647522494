import '../css/Navbar.css'; // Importa o arquivo css da navbar
import { DarkTheme, LightTheme } from '../util/config'; // Importa os temas do arquivo de configurações

/**
 * @name Navbar
 * @description Retorna o componente da barra de navegação / navbar
 * @param {Object} props
 * @param {string} props.route
 * @param {Object} props.currentTheme
 * @param {function} props.setTheme
*/

const URLs = [
  { name: "INÍCIO", href: "/" },
  { name: "SOBRE", href: "/sobre" },
  { name: "PROJETOS", href: "/projetos" }
];

function Navbar({ route, currentTheme, setTheme }) {

  /**
   * @name ChangeTheme
   * @description Altera o tema atual do webisite
   * @param {Object} theme
   */

  const ChangeTheme = (theme) => {
    setTheme(theme);
    window.localStorage.setItem("nextdiv-theme", theme.name);
  };

  return (
    <header className='navbar' style={{ background: currentTheme.navbar, color: currentTheme.textColor }}>
      <img
        className='text-icon'
        alt='Ícone da NextDiv.'
        src={
          // Altera o header para a versão clara e escura dependendo do tema
          `/images/${currentTheme.name === 'dark' ? 'header' : 'header-dark'}.png`
        } />
      <img
        className='rounded-icon'
        src='/images/icon.png'
        alt='Ícone da NextDiv.'
      />
      <div className='navbar-items'>
        <nav>
          {URLs.map(url => {
            return (
              <a
                className={route === url.href ? 'active' : ''}
                style={{ color: currentTheme.textColor }}
                key={url.name}
                href={url.href}
              >
                {url.name}
              </a>
            );
          })}
        </nav>
        <div className='theme-switcher'>
          <div
            className={currentTheme.name}
            onClick={
              // Altera o tema quando clicado
              () => ChangeTheme(currentTheme.name === "dark" ? LightTheme : DarkTheme)
            }>
            <i className={
              // Altera entre lua e o sol
              `bx ${currentTheme.name === "dark" ? 'bxs-moon' : 'bxs-sun'}`
            }></i>
          </div>
        </div>
      </div>
    </header>
  );
}

// Exporta o componente
export default Navbar
