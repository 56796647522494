import { useState, useEffect } from "react"; // Importa as funções do react
import Helmet from "react-helmet"; // Importa o Helmet para cabeçalhos de página

import { DarkTheme, LightTheme } from "../util/config"; // Importa os temas

import "../css/Home.css"; // Importa o CSS

import Navbar from "../Components/Navbar"; // Importa a Navbar
import Footer from "../Components/Footer"; // Importa o Footer

function Home() {
  // Salva o valor do tema, usando um state
  const [currentTheme, setTheme] = useState(DarkTheme);

  // Copia para a clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  // Callback chamado após o carregamento do site
  useEffect(() => {
    // Coleta o tema salvo no local storage do navegador
    let theme = window.localStorage.getItem("nextdiv-theme");

    // Salva o tema padrão caso não exista
    if (!theme) {
      theme = "dark";
      window.localStorage.setItem("nextdiv-theme", "dark");
    }

    // Atualiza o estado com a informação do local storage
    setTheme(theme === "dark" ? DarkTheme : LightTheme);
  }, []);

  return (
    <div className='home' style={{ background: currentTheme.background }}>
      <Helmet>
        <title>NextDiv - Desenvolvimento de Software</title>
      </Helmet>

      <main style={{ color: currentTheme.textColor }}>
        <Navbar route="/" currentTheme={currentTheme} setTheme={setTheme} />
        
        <div className='text-box'>
          <h1 className='p1 anim-text'>{"Next</Div>"}</h1>
          <h2 className='p2 anim-text'>Conectando o seu negócio com o mundo!</h2>
        </div>

        <div className='contact'>
          <div className='email'>
            <h2>
              <i class='bx bx-envelope'
                onMouseOver={(ev) => ev.currentTarget.classList.add("bx-tada")}
                onMouseLeave={(ev) => ev.currentTarget.classList.remove("bx-tada")}>
              </i>
              Email: 
            </h2>
            <strong
              onClick={() => copyToClipboard('contato@nextdiv.com.br')}>
              contato@nextdiv.com.br
            </strong>
          </div>

          <div className='phones'>
            <h2>
              <i className='bx bxl-whatsapp'
                onMouseOver={(ev) => ev.currentTarget.classList.add("bx-tada")}
                onMouseLeave={(ev) => ev.currentTarget.classList.remove("bx-tada")}>
              </i>
              Whatsapp: 
            </h2>
            <div>
              <strong
                onClick={() => copyToClipboard('19997834256')}>
                (19) 99783-4256
              </strong>
              <strong
                onClick={() => copyToClipboard('19981766584')}>
                (19) 98176-6584
              </strong>
            </div>
          </div>
        </div>
      </main>

      <Footer theme={currentTheme} />
    </div>
  );
}

// Exporta o componente
export default Home 
