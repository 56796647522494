/**
 * @name DarkTheme
 * @description Configurações do tema escuro
 */

export const DarkTheme = {
    name: "dark",
    textColor: '#ffffff',
    navbar: '#0b0c109a',
    background: '#0b0c10',
    footer: '#0b0c10',
    socialMedias: '#2e2e2e',
    contact: "#1f1f1f",
};

export const LightTheme = {
    name: 'light',
    textColor: '#292929',
    navbar: '#d4d4d49a',
    background: '#d4d4d4',
    footer: '#d4d4d4', // #bdbdbd
    socialMedias: '#afafaf',
    contact: "#1f1f1f",
};