import '../css/404.css'
import { DarkTheme } from '../util/config';

function NotFound() {
  return (
    <div className='not-found' style={{ background: DarkTheme.background }}>
      <main>
        <div>
          <h1 style={{ color: DarkTheme.textColor }}>404 - Página não encontrada!</h1>
          <strong><a href='/' style={{ color: DarkTheme.textColor }}>VOLTAR PARA O INÍCIO</a></strong>
        </div>
      </main>
    </div>
  );
}

export default NotFound
