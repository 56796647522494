import { useState, useEffect } from "react"; // Importa as funções do react

import "../css/Projetos.css"; // Importa o css 
import { DarkTheme, LightTheme } from "../util/config"; // Importa os temas

import Navbar from "../Components/Navbar"; // Importa a Navbar
import Footer from "../Components/Footer"; // Importa o Footer

import projetos from "../util/projetos";

function Projetos() {
  // Salva o valor do tema, usando um state
  const [currentTheme, setTheme] = useState(DarkTheme);

  // Callback chamado após o carregamento do site
  useEffect(() => {
    // Coleta o tema salvo no local storage do navegador
    let theme = window.localStorage.getItem("nextdiv-theme");

    // Salva o tema padrão caso não exista
    if (!theme) {
      theme = "dark";
      window.localStorage.setItem("nextdiv-theme", "dark");
    }

    // Atualiza o estado com a informação do local storage
    setTheme(theme === "dark" ? DarkTheme : LightTheme);
  }, []);

  return (
    <div className='projects' style={{ background: currentTheme.background }}>
      <main style={{ color: currentTheme.textColor}}>
        <Navbar route="/projetos" currentTheme={currentTheme} setTheme={setTheme} />

        <h1 className='anim-text'>Projetos da {"Next</Div>"}</h1>
        <div className='projects-list'>
          {projetos.map(project => {
            return (
              <figure onClick={() => window.open(project.href, "_blank")}>
                <img 
                  src={project.images[0]}
                  name={`Projeto - ${project.name}`}
                  alt={`Imagem - ${project.name}`} 
                />
                <figcaption>
                  <strong>{project.name}</strong> <br />
                  {project.descrition}
                </figcaption>
              </figure>  
            );
          })}
        </div>
      </main>
      <Footer theme={currentTheme}/>
  </div>
  );
}

// Exporta o componente
export default Projetos
