// Importa bibliotecas do react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import './css/index.css'; // Importa arquivo de css principal

// Importa páginas e componentes
import Home from './Routes/Home.js';
import Sobre from './Routes/Sobre.js';
import NotFound from './Routes/404.js';
import Projetos from './Routes/Projetos.js';

import reportWebVitals from './reportWebVitals'; // Importa o serviço de estatísticas

// Procura o elemento root do arquivo index.html
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderiza o site
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/sobre' element={<Sobre />} caseSensitive={false} />
        <Route path='/projetos' element={<Projetos />} caseSensitive={false} />
        <Route path='*' element={<NotFound />} caseSensitive={false} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// Inicializa as estatísticas
reportWebVitals();