import { useState, useEffect } from "react"; // Importa as funções do react
import Helmet from "react-helmet"; // Importa o Helmet para cabeçalhos de página

import "../css/Sobre.css"; // Importa o css 
import { DarkTheme, LightTheme } from "../util/config"; // Importa os temas

import Navbar from "../Components/Navbar"; // Importa a Navbar
import Footer from "../Components/Footer"; // Importa o Footer

function Sobre() {
  // Salva o valor do tema, usando um state
  const [currentTheme, setTheme] = useState(DarkTheme);

  // Callback chamado após o carregamento do site
  useEffect(() => {
    // Coleta o tema salvo no local storage do navegador
    let theme = window.localStorage.getItem("nextdiv-theme");

    // Salva o tema padrão caso não exista
    if (!theme) {
      theme = "dark";
      window.localStorage.setItem("nextdiv-theme", "dark");
    }

    // Atualiza o estado com a informação do local storage
    setTheme(theme === "dark" ? DarkTheme : LightTheme);
  }, []);

  return (
    <div className='about' style={{ background: currentTheme.background }}>
      <Helmet>
        <title>NextDiv - Sobre</title>
      </Helmet>

      <main style={{ color: currentTheme.textColor}}>
        <Navbar route="/sobre" currentTheme={currentTheme} setTheme={setTheme} />

        <div className='content'>
          <h1 className="anim-text">Sobre a {"Next</Div>"}</h1>
          <p>
            A NextDiv é mais do que uma simples startup, somos seus parceiros na jornada para o sucesso.
            Nosso foco é contribuir com comerciantes e empreendedores, desenvolvendo software personalizado que 
              automatiza sistemas de acordo com as necessidades específicas de cada cliente. <br />
          </p>
          <p>
            Na essência da NextDiv, está nossa dedicação à entrega ágil e segura.
            Sempre colocamos o cliente em primeiro lugar, pensando constantemente no que é melhor para eles. <br /> 
          </p>
          <p>
            Essa mentalidade orienta todas as nossas ações, desde o desenvolvimento de produtos 
              até o atendimento ao cliente. 
          </p>
        </div>
      </main>
      <Footer theme={currentTheme}/>
  </div>
  );
}

// Exporta o componente
export default Sobre
