const projects = [
    {
        name: "Escritório Brasil",
        href: "http://escritoriocontbrasil.com.br",
        descrition: "Website para um escritório de contabilidade.",
        images: [
            "/images/projects/escritorio-brasil.png"
        ]
    },
];

export default projects
